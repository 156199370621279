import React, { useState } from 'react';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import styled, { css } from 'styled-components';
import {
    SectionContainer,
    ContentWrapper,
    SectionHeading,
} from '~/styles/common';
import { CTAButton } from '~/styles/welcome';
import TransHTML from '../transHTML';
import { getDiscount, getPrice, Mode, Tier } from "~services/business/pricing";
import { Currency } from "~services/i18n/currency";
import AmountFormat from "~components/i18n/amount-format";
import { Country } from "~services/i18n/country";
import { paths } from '~/../shared/routing';
import { getPath } from '~/../shared/utils';
import { tabletBreakpoint } from '~/styles/common';

type PropTypes = {
    country: Country;
    currency: Currency;
    language: string;
};

// todo: this is probably big duplicity to what we have in "main pricing", merge it!
export function OcellotPricing({ country, currency, language }: PropTypes) {
    const [mode, setMode] = useState<Mode>(Mode.MONTHLY);

    const onSelectSide = (): void => {
        if (mode === Mode.MONTHLY) {
            setMode(Mode.YEARLY);
        } else {
            setMode(Mode.MONTHLY);
        }
    }

    return (
        <PaymentAcceptanceSectionContainer id="payment-acceptance">
            <SectionHeading>
                <TransHTML id={t`cz.ocellot.index.price.title`} />
            </SectionHeading>
            <SwitchWrapper>
                <PricingSwitch>
                    <SelectSide
                        type="button"
                        onClick={onSelectSide}
                        active={mode === Mode.MONTHLY}
                    >
                        <Trans id="global.pricing.monthly" />
                    </SelectSide>
                    <SelectSide
                        type="button"
                        onClick={onSelectSide}
                        active={mode === Mode.YEARLY}
                    >
                        <Trans id="global.pricing.yearly" />
                        <YearlyDiscount active={mode === Mode.YEARLY}>%</YearlyDiscount>
                    </SelectSide>
                </PricingSwitch>
            </SwitchWrapper>

            <PricingTableWrapper>
                <CardHeading>
                    <TransHTML id={t`cz.ocellot.index.price.text`} />
                </CardHeading>

                <PriceTableCardPremium>

                    <PricingDetails>
                        <Price>
                            {mode === Mode.MONTHLY && (
                                <AmountFormat
                                    noSign
                                    amount={getPrice(Tier.TEST, mode, currency)}
                                    currencyEnum={currency}
                                    language={language}
                                />
                            )}
                            {mode === Mode.YEARLY && (
                                <>
                                    <PremiumSavings>
                                        <Trans id="global.pricing.priceTable.premium.price.yearly.savings" />
                                        {' '}
                                        <AmountFormat
                                            noSign
                                            textColor='inherit'
                                            amount={getDiscount(Tier.TEST, currency, true)}
                                            currencyEnum={currency}
                                            language={language}
                                        />
                                    </PremiumSavings>
                                    <AmountFormat
                                        noSign
                                        textColor='inherit'
                                        amount={getPrice(Tier.TEST, mode, currency)}
                                        currencyEnum={currency}
                                        language={language}
                                    />
                                    <YearlyPrice>
                                        (
                                        <AmountFormat
                                            noSign
                                            textColor='inherit'
                                            amount={getPrice(Tier.TEST, mode, currency, true)}
                                            currencyEnum={currency}
                                            language={language}
                                        />
                                        {' '}
                                        <Lowercase>
                                            <Trans id="global.pricing.yearly" />
                                        </Lowercase>
                                        )
                                    </YearlyPrice>
                                </>
                            )}
                        </Price>
                        <PriceDescription>
                            <Trans id="global.pricing.priceTable.premium.description" />
                        </PriceDescription>
                    </PricingDetails>
                </PriceTableCardPremium>

            </PricingTableWrapper>

            <ButtonBlock>
                <CTAButton href={getPath(paths.pos_sign_up, country)}>
                    <Trans id="cz.ocellot.index.signUpButton" />
                </CTAButton>
            </ButtonBlock>
        </PaymentAcceptanceSectionContainer>
    );
}

const ButtonBlock = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    @media (min-width: ${tabletBreakpoint}) {
        margin-top: 4rem;
    }
`;

const SwitchWrapper = styled.div`
    margin: 2rem;
    display: flex;
    justify-content: center;
`;

const PricingSwitch = styled.div`
    background: ${({ theme }) => theme.lightPurple};
    border-radius: 22px;
    box-shadow: ${({ theme }) => theme.switchBoxShadow};
`;

const SelectSide = styled.button`
    height: 100%;
    background: none;
    box-shadow: none;
    border-radius: 22px;
    border: none;
    padding: 8px 20px;
    font-family: ${({ theme }) => theme.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.833333px;
    color: ${({ theme }) => theme.darkPurple3};
    text-transform: uppercase;
    ${({ active }) => active && css`
        background: ${({ theme }) => theme.darkPurple};
        box-shadow: 0px 5px 7px rgba(82, 67, 194, 0.23);
        color: #EBE7F7;
        transition: background 0.5s, color 0.5s;
    `}
`;

const YearlyDiscount = styled.span`
    background: ${({ theme }) => theme.darkPurple};
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #EBE7F7;
    border-radius: 30px;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-left: 10px;
    margin-right: -10px;
    ${({ active }) => active && css`
        background: #EBE7F7;
        color: ${({ theme }) => theme.darkPurple2};
        transition: background 0.5s, color 0.5s;
    `}
`;

const PricingTableWrapper = styled(ContentWrapper)`
    font-family: ${({ theme }) => theme.primaryFont};
    display: flex;
    gap: 0;

    @media (min-width: ${tabletBreakpoint}) {
        flex-direction: unset;
        gap: 4rem;
    }
`;

const PriceTableCardFree = styled.div`
    background: ${({ theme }) => theme.lightPurple};
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-flow: column;
    min-width: 330px;
    flex: 1;
`;

const PriceTableCardPremium = styled(PriceTableCardFree)`
    background: none;
    padding: 2.5rem;
    border: 0.5px solid #676AA7;
`;

const CardHeading = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.8;
    color: #170A07;
    margin-bottom: 3rem;

    @media (min-width: ${tabletBreakpoint}) {
        margin-top: 4rem;
        max-width: 380px;
    }
`;

const PricingDetails = styled.div`

`;

const Price = styled.div`
    font-family: ${({ theme }) => theme.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #170A07;
    position: relative;
`;

const YearlyPrice = styled.span`
    font-weight: 400;
    font-size: 15px;
    color: #616163;
    margin-left: 8px;
    line-height: 10px;
`;

const PremiumSavings = styled.div`
    background: #685BC7;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.lightPurple};
    position: absolute;
    left: 0;
    top: -1.5rem;
`;

const PriceDescription = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #616163;
`;

const Lowercase = styled.span`
    text-transform: lowercase;
`;

const PaymentAcceptanceSectionContainer = styled(SectionContainer)`
    background-color: white;
`;
