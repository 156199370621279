import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import { withI18n } from "@lingui/react";
import Layout from '~/components/layout/_layout';
import SEO from '~/components/seo';
import PageTypes from '~/page_types';
import FadeInSection from '~/components/fadeinSection';
import HaveAQuestion from '~/components/homepage/haveAQuestion.jsx';
import { LangContext } from '~/providers/LangProvider';
import { Country } from "~services/i18n/country";
import FeaturesDesktop from '~components/ocellot/features/desktop';
import FeaturesMobile from '~components/ocellot/features/mobile';
import {
    CrossPlatform,
    CloudDashboard,
    UxFriendly,
    OcellotPricing
} from '~components/ocellot';


const demoBlogArticle = '/cs/CZ/blog/vyzkousejte-si-pokladni-system-v-demo-verzi';

function OcellotPage({ i18n }) {
    const ctxLang = useContext(LangContext);
    const pageSections = [
        <FeaturesDesktop />,
        <FeaturesMobile />,
        <UxFriendly i18n={i18n} />,
        <CrossPlatform
            demoLink={demoBlogArticle}
            i18n={i18n}
        />,
        <CloudDashboard i18n={i18n} />,
        <OcellotPricing
            country={Country.CZ}
            currency={ctxLang.state.currency}
            language={ctxLang.state.lang}
        />,
        <HaveAQuestion supportContact={true} whiteBg='white' />
    ];
    return (
        <Layout
            menuWithoutCTAs={true}
            welcome={true}
            page={PageTypes.OCELLOT}
        >
            <SEO
                title={t`cz.ocellot.index.seo.title`}
                description={t`cz.ocellot.index.seo.description`}
            />
            {pageSections.map((section, id) => {
                return <FadeInSection key={id}>{section}</FadeInSection>;
            })}
        </Layout>
    );
}

export default withI18n()(OcellotPage);
