import React from 'react';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import { smallScreenBreakpoint1064px, tabletBreakpoint } from '~/styles/common';
import TransHTML from '~/components/transHTML';

export const CloudDashboard = ({ i18n }: { i18n: any }) => (
    <SignBusinessWrapper>
        <Ico alt='Trisbee Business' src={'/static/images/ocellot/icons/trisbee-ico.svg'} />
        <ImgBlock alt='Trisbee Business' src={i18n._(t`cz.ocellot.index.sign.business.image`)} />
        <SubHeading>
            <TransHTML id={t`cz.ocellot.index.business.text`} />
        </SubHeading>
    </SignBusinessWrapper>
);

const SignBusinessWrapper = styled.section`
    padding: 2rem 1rem;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @media (min-width: ${tabletBreakpoint}) {
        gap: 2.5rem;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        padding: 4rem 1rem;
    }
`;

const ImgBlock = styled.img`
    display: block;
    max-width: 100%;
    width: 350px;
    object-fit: contain;
    margin-bottom: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 700px;
    }
`;

const Ico = styled.img`
    object-fit: contain;
`;

const SubHeading = styled.div`
    line-height: 1.8;
    font-weight: 300;
    font-size: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        font-size: 1.3rem;
        max-width: 540px;
        padding-right: 1rem;
        font-weight: 400;
        text-align: center;
    }
`;




