import {Tier} from "~services/business/pricing/tier";
import {Mode} from "~services/business/pricing/mode";
import {Currency} from "~services/i18n/currency";

type PriceByCurrency = {
    [key in Currency]: number;
}

type Structure = {
    [key in Tier]: {
        [key in Mode]: {
            price: PriceByCurrency;
            limit?: PriceByCurrency;
        };
    }
}

const priceStandardTier = {
    [Currency.CZK]: 0,
    [Currency.EUR]: 0,
}

const limitForStandardTier = {
    [Currency.CZK]: 50000,
    [Currency.EUR]: 2000,
};

/**
 * define price for each tier and currency
 */
export const Configuration: Structure = {
    [Tier.START]: {
        [Mode.MONTHLY]: {
            price: priceStandardTier,
            limit: limitForStandardTier,
        },
        [Mode.YEARLY]: {
            price: priceStandardTier,
            limit: limitForStandardTier,
        }
    },
    [Tier.PLUS]: {
        [Mode.MONTHLY]: {
            price: {
                [Currency.CZK]: 490,
                [Currency.EUR]: 19,
            },
        },
        [Mode.YEARLY]: {
            price: {
                [Currency.CZK]: 408,
                [Currency.EUR]: 16,
            },
        },
    },
    [Tier.PREMIUM]: {
        [Mode.MONTHLY]: {
            price: {
                [Currency.CZK]: 790,
                [Currency.EUR]: 38,
            },
        },
        [Mode.YEARLY]: {
            price: {
                [Currency.CZK]: 658,
                [Currency.EUR]: 36,
            },
        },
    },
    [Tier.TEST]: {
        [Mode.MONTHLY]: {
            price: {
                [Currency.CZK]: 790,
                [Currency.EUR]: 19,
            },
        },
        [Mode.YEARLY]: {
            price: {
                [Currency.CZK]: 658,
                [Currency.EUR]: 16,
            },
        },
    },
};