import {Currency} from "~services/i18n/currency";

export interface CurrencyFormat {
    price: string;
    currency: string;
    currencyRight: boolean;
    spaceBetween: boolean;
    totalFormattedPrice: string;
}

/**
 * Localization formatting of the amount
 */
export function formatCurrency(amount: number, currency: Currency, locale: string): CurrencyFormat {

    const conf = {currency, style: 'currency'};
    const parts = new Intl.NumberFormat(locale, conf).formatToParts(amount);

    let finalCurrency = parts.find(item => item.type === 'currency')!.value;

    const finalPrice = parts
        .filter(item => !['currency', 'literal'].includes(item.type))
        .map(item => item.value).join('')
        .replace(/(\.|,)00$/g, '');

    return {
        price: finalPrice,
        currency: finalCurrency,
        currencyRight: CurrencyToRight(locale, currency),
        spaceBetween: true,
        totalFormattedPrice: `${finalPrice} ${finalCurrency}`
    }
}

/**
 * Currency symbol on right side of the amount
 */
export function CurrencyToRight(locale: string, currency: Currency): boolean {
    let toRight = true;

    if (locale !== 'cs' && currency === Currency.CZK) {
        toRight = false;
    }

    if (locale === 'en' && currency === Currency.EUR) {
        toRight = false;
    }

    return toRight;
}
