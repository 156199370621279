import React from 'react';
import { t } from '@lingui/macro';
import {withI18n} from "@lingui/react";
import styled from 'styled-components';
import TransHTML from '~/components/transHTML';
import { smallScreenBreakpoint1064px, tabletBreakpoint } from '~/styles/common';
import {OperationSystems} from "~components/ocellot/features/os";
import {benefitsData} from "~components/ocellot/features/data";
import {BenefitItem} from "~components/ocellot/features/item";

const FeaturesDesktop = () => (
    <Wrapper>
        <Container>
            <Text>
                <Heading>
                    <TransHTML id={t`cz.ocellot.index.features.title`} />
                </Heading>
                <SubHeading>
                    <TransHTML id={t`cz.ocellot.index.features.subtitle`} />
                </SubHeading>
                <OperationSystems />
            </Text>
            <Block>
                {benefitsData.map((item) => (
                    <BenefitItem
                        key={item.iconSrc}
                        iconAlt={item.iconAlt}
                        iconSrc={item.iconSrc}
                        text={item.text}
                    />
                ))}
            </Block>
        </Container>
    </Wrapper>
);

export default withI18n()(FeaturesDesktop);

const Wrapper = styled.section`
    display: none;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        padding: 0 1rem 3rem 1rem;
        display: block;
    }
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 500px;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 990px;
    width: 100%;
    border-radius: 16px;
    margin: 0 auto;
    position: relative;

    @media (min-width: ${tabletBreakpoint}) {
        align-items: flex-start;
        padding: 3rem;
    }
`;

const Block = styled.div`
    margin-top: 25px;
`;

const Heading = styled.h3`
    line-height: 1.2;
    font-weight: 600;

    @media (min-width: ${tabletBreakpoint}) {
        margin-bottom: 4rem;
        font-size: 3rem;
    }
`;

const SubHeading = styled.div`
    line-height: 1.8;
    font-weight: 400;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 400px;
        margin-bottom: 0;
        font-size: 1.2rem;
    }
`;




