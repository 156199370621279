import React from "react";
import styled from "styled-components";
import {Currency} from "~services/i18n/currency";
import {formatCurrency} from "~services/i18n/currency-format";

type PropTypes = {
    amount: number;
    currencyEnum: Currency;
    className?: string;
    crossed?: boolean;
    appendPlus?: boolean;
    noSign?: boolean;
    textColor?: string | null;
    language: string;
}

/**
 *
 * Show formatter amount based on currency and language
 * Optionally you can append positive symbol or cross whole text (chargeback case)
 *
 */
function AmountFormat({
    amount,
    currencyEnum,
    className,
    crossed,
    appendPlus,
    noSign,
    textColor = null,
    language
}: PropTypes) {
    const currencyFormat = formatCurrency(amount, currencyEnum, language);
    const {currency, price, currencyRight, spaceBetween} = currencyFormat;
    const sign = noSign ? '' : appendPlus ? '+' : '-';

    const currencySymbol = (
        <CurrencySymbol textColor={textColor} className={`currency`}>
            {currency}
        </CurrencySymbol>
    );

    return (
        <span className={className}>
            {!currencyRight && currencySymbol}
            {!currencyRight && spaceBetween && ` `}
            <Amount textColor={textColor} crossed={crossed} className={`amount`}>
                <Sign className={`sign`}>
                    {sign}
                </Sign>
                {price}
            </Amount>
            {currencyRight && spaceBetween && ` `}
            {currencyRight && currencySymbol}
        </span>
    );
}

export default AmountFormat;

const Amount = styled.span<{ textColor?: string | null, crossed?: boolean }>`
    text-decoration: ${({crossed}) => crossed ? 'line-through' : 'none'};
    color: ${({textColor}) => textColor ? textColor : '#000000'};
`;

const Sign = styled.i`
    font-style: inherit;
`;

const CurrencySymbol = styled.span<{ textColor?: string | null }>`
    color: ${({textColor}) => textColor ? textColor : '#000000'};
`;

