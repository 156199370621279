import {Tier} from "~services/business/pricing/tier";
import {Configuration} from "~services/business/pricing/configuration";
import {Mode} from "~services/business/pricing/mode";
import {Currency} from "~services/i18n/currency";

/**
 * get price by your options and currency
 */
export function getPrice(tier: Tier, mode: Mode, currency: Currency, forYearAmount?: boolean): number {
    const amount = Configuration[tier][mode].price[currency];
    if (forYearAmount) {
        return getYearlyAmount(amount);
    }
    return amount;
}

/**
 * free tier have a turnover limit
 */
export function getMonthlyTurnoverLimit(tier: Tier, mode: Mode, currency: Currency): null | number {
    return Configuration[tier][mode]?.limit?.[currency] || null;
}

/**
 * if you choose yearly payment, you will get the discount
 */
export function getDiscount(tier: Tier, currency: Currency, forYearAmount?: boolean): null | number {
    const monthly = Configuration[tier][Mode.MONTHLY].price[currency];
    const yearly =  Configuration[tier][Mode.YEARLY].price[currency];
    const discount = (monthly - yearly);
    if (discount === 0) {
        return null;
    }
    if (forYearAmount) {
        return getYearlyAmount(discount);
    }
    return discount;
}

/**
 * we have the amounts set in months, so divide by 12 months and round for money
 */
function getYearlyAmount(amount: number): number {
    return Math.round(amount * 12 / 10) * 10;
}
