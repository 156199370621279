import React from 'react';
import TransHTML from '~/components/transHTML';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import { smallScreenBreakpoint1064px, tabletBreakpoint } from '~/styles/common';
import {BasicButtonLink} from "~styles/buttons";

type PropTypes = {
    demoLink: string;
    i18n: any;
}

export function CrossPlatform({ demoLink, i18n }: PropTypes) {
    return (
        <Wrapper>
            <Container>
                <Text>
                    <Heading>
                        <TransHTML id={t`cz.ocellot.index.calculator.title`} />
                    </Heading>
                    <SubHeading>
                        <TransHTML id={t`cz.ocellot.index.calculator.subtitle`} />
                    </SubHeading>
                </Text>
                <ImgBlock
                    alt='Screenshot from POS with tables'
                    src={i18n._(t`cz.ocellot.index.calculator.image`)}
                />
            </Container>
            <DemoBtn href={demoLink}>
                <span>
                    <TransHTML id={t`cz.ocellot.index.demoUrl`} />
                </span>
            </DemoBtn>
        </Wrapper>
    );
}

const DemoBtn = styled.a`
    ${BasicButtonLink};

    min-width: 0;
    padding: 8px 17px;
    font-weight: 600;
    margin: 0 auto;
    text-transform: uppercase;

    color: #000000;
    background: transparent;

    border: 1px solid #000000;

    &:hover {
        background-color: #000000;
        color: white;
    }
`;

const Wrapper = styled.section`
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 3rem 0;
    }
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        max-width: unset;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    max-width: 990px;
    width: 100%;
    border-radius: 16px;
    padding: 2rem 1rem;
    margin: 0 auto;

    @media (min-width: ${tabletBreakpoint}) {
        flex-direction: unset;
        padding: 5rem 5rem 5rem 1rem;
    }
`;

const ImgBlock = styled.img`
   width: 85%;
   object-fit: contain;
   order: -1;

   @media (min-width: ${tabletBreakpoint}) {
        background-position-x: right;
        margin-bottom: 0;
        width: 50%;
        order: 0;
    }
`;

const Heading = styled.h3`
    line-height: 1.6;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 400px;
        margin-bottom: 2rem;
        font-size: 1.5rem;
    }
`;

const SubHeading = styled.div`
    line-height: 2;
    font-size: 1rem;
    font-weight: 400;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 400px;
        margin-bottom: 0;
        font-size: 1.2rem;
    }
`;




