import React from 'react';
import { t } from '@lingui/macro';
import {withI18n} from "@lingui/react";
import styled from 'styled-components';
import TransHTML from '~/components/transHTML';
import { smallScreenBreakpoint1064px } from '~/styles/common';
import {OperationSystems} from "~components/ocellot/features/os";
import {benefitsData} from "~components/ocellot/features/data";
import {BenefitItem} from "~components/ocellot/features/item";

// todo: this is really weird, it should be in one responsive component, not divided like this
const FeaturesMobile = () => {
    const benefitsDataFirst3 = benefitsData.slice(0, 3);
    const benefitsDataSecond3 = benefitsData.slice(3, 6);
    return (
        <Wrapper>
            <Container>
                <Content>
                    <Heading>
                        <TransHTML id={t`cz.ocellot.index.features.title`} />
                    </Heading>
                    <Block>
                        <Column>
                            {benefitsDataFirst3.map((item) => (
                                <BenefitItem
                                    key={item.text}
                                    iconAlt={item.iconAlt}
                                    iconSrc={item.iconSrc}
                                    text={item.text}
                                />
                            ))}
                        </Column>
                        <Column>
                            {benefitsDataSecond3.map((item) => (
                                <BenefitItem
                                    key={item.text}
                                    iconAlt={item.iconAlt}
                                    iconSrc={item.iconSrc}
                                    text={item.text}
                                />
                            ))}
                        </Column>
                    </Block>
                    <Compatibility>
                        <SubHeading>
                            <TransHTML id={t`cz.ocellot.index.features.subtitle`} />
                        </SubHeading>
                        <OperationSystems />
                    </Compatibility>
                </Content>
            </Container>
        </Wrapper>
    );
}

export default withI18n()(FeaturesMobile);

const Column = styled.div`

`;

const Wrapper = styled.section`
    display: block;
    padding: 2rem 1.3rem;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        display: none;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
`;

const Compatibility = styled.div`
    margin: 0 auto;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    max-width: 990px;
    width: 100%;
    border-radius: 16px;
    margin: 0 auto;
    position: relative;
`;

const Block = styled.div`
    margin: 3rem auto 4rem;
    display: flex;
    justify-content: center;
    gap: 5rem;

    @media (max-width: 600px) {
        display: block;
    }
`;

const Heading = styled.h3`
    line-height: 1.5;
    font-weight: 600;
    font-size: 1.5em;
    text-align: center;
`;

const SubHeading = styled.div`
    line-height: 1.8;
    font-weight: 400;
    text-align: center;
    margin-bottom: 2rem;
`;




