import TransHTML from "~components/transHTML";
import React from "react";
import styled from "styled-components";

export type BenefitItemType = {
    iconAlt: string;
    iconSrc: string;
    text: string;
}

export const BenefitItem = ({ iconAlt, iconSrc, text }) => (
    <Item>
        <Icon
            alt={iconAlt}
            src={iconSrc}
        />
        <Text>
            <TransHTML id={text} />
        </Text>
    </Item>
);

const Item = styled.div`
    display: flex;
    gap: .7rem;
    align-items: center;
    margin-bottom: 1.2rem;
`;

const Text = styled.p`
    margin-bottom: 0;
    font-size: 1.3rem;
`;

const Icon = styled.img`
`;
