import { t } from '@lingui/macro';
import {BenefitItemType} from "~components/ocellot/features/item";

// todo: find out why lingui do not see these localization keys in-use!
export const benefitsData: BenefitItemType[] = [
    {
        iconAlt: '',
        iconSrc: '/static/images/ocellot/icons/benefits/calculator.svg',
        text: t`cz.ocellot.index.features.data.cash_register`,
    },
    {
        iconAlt: '',
        iconSrc: '/static/images/ocellot/icons/benefits/calendar-dot.svg',
        text: t`cz.ocellot.index.features.data.stock_taking`,
    },
    {
        iconAlt: '',
        iconSrc: '/static/images/ocellot/icons/benefits/analysis.svg',
        text: t`cz.ocellot.index.features.data.warehouse_management`,
    },
    {
        iconAlt: '',
        iconSrc: '/static/images/ocellot/icons/benefits/every-user.svg',
        text: t`cz.ocellot.index.features.data.shift_tracking`,
    },
    {
        iconAlt: '',
        iconSrc: '/static/images/ocellot/icons/benefits/transaction.svg',
        text: t`cz.ocellot.index.features.data.daily_reports`,
    },
    {
        iconAlt: '',
        iconSrc: '/static/images/ocellot/icons/benefits/address-book.svg',
        text: t`cz.ocellot.index.features.data.suppliers_overview`,
    },
];
