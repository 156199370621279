import React from 'react';
import styled from 'styled-components';
import { t } from '@lingui/macro';
import TransHTML from '~/components/transHTML';
import { smallScreenBreakpoint1064px, tabletBreakpoint } from '~/styles/common';

export const UxFriendly = ({ i18n }: { i18n: any }) => (
    <Wrapper>
        <Container>
            <ImgBlock
                alt='Screenshot from POS'
                src={i18n._(t`cz.ocellot.index.image`)}
            />
            <Text>
                <Heading>
                    <TransHTML id={t`cz.ocellot.index.technology.title`}/>
                </Heading>
                <SubHeading>
                    <TransHTML id={t`cz.ocellot.index.technology.subtitle`}/>
                </SubHeading>
            </Text>
        </Container>
    </Wrapper>
);

const Wrapper = styled.section`
    padding: 1.5rem 0;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 3rem 0;
    }
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        max-width: unset;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    max-width: 990px;
    width: 100%;
    padding: 2rem 1rem;
    margin: 0 auto;
    background: #FAFAFA;
    border-radius: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        flex-direction: unset;
        padding: 5rem 5rem 5rem 1rem;
    }
`;

const ImgBlock = styled.img`
   width: 85%;
   object-fit: contain;

   @media (min-width: ${tabletBreakpoint}) {
        background-position-x: right;
        margin-bottom: 0;
        width: 50%;
    }
`;

const Heading = styled.h3`
    line-height: 1.6;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 400px;
        margin-bottom: 2rem;
        font-size: 1.5rem;
    }
`;

const SubHeading = styled.div`
    line-height: 2;
    font-size: 1rem;
    font-weight: 400;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 400px;
        margin-bottom: 0;
        font-size: 1.2rem;
    }
`;




